import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Checkbox, Form, Input, Image, Divider, List, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { writeCookie, getCookie } from '../utils/utils';
import { GET_CHARACTER } from '../graphql/Queries';

const Character = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [character, setCharacter] = useState({});
    const [favoriteChk, setFavoriteChk] = useState(undefined);
    const [form] = Form.useForm();
    const { loading, error, data } = useQuery(GET_CHARACTER, {
        variables: { id: parseInt(params.id) }
    });

    useEffect(() => {
        if (data) {
            // Datos de favoritos, Guardo la primera Cookie
            const cookie = getCookie('favorites');
            if (cookie) {
                const favorites = JSON.parse(cookie);
                console.log(favorites?.find(d => d.id == data.character.id));
                setFavoriteChk(favorites?.find(d => d.id == data.character.id)?.favorite);
            } else {
                const favorite = {
                    id: data.character.id,
                    favorite: false,
                    image: data.character.image,
                    name: data.character.name,
                }
                writeCookie('favorites', JSON.stringify([favorite]), 30);
            }
            // Datos del fomulario
            setCharacter({ ...data.character });
            form.setFieldsValue({
                ...data.character
            });
        }
        return () => { }
    }, [data])


    /**
     * Maneja la lista de favoritos desde la Cookies
     * @param {*} e 
     */
    const handleFavorite = (e) => {
        const favorites = JSON.parse(getCookie('favorites'));
        if (favorites) {
            const favoriteFind = favorites.find(d => d.id == character.id);
            if(!favoriteFind) {
                favorites.push({
                    id: character.id,
                    favorite: e.target.checked,
                    image: character.image,
                    name: character.name
                });
            }
            const favoritesNew = [];
            for (const item of favorites) {
                if (item.id == character.id) {
                    favoritesNew.push({
                        id: item.id,
                        favorite: e.target.checked,
                        image: item.image,
                        name: item.name
                    });
                } else {
                    favoritesNew.push({
                        id: item.id,
                        favorite: item.favorite,
                        image: item.image,
                        name: item.name
                    });
                }
            }
            writeCookie('favorites', JSON.stringify(favoritesNew), 30);
        }
        setFavoriteChk(e.target.checked);
    }


    return (
        <div style={{ padding: 20 }}>
            <Card style={{ width: '100%', height: '100%', margin: 'auto' }} title='¡Un personaje un poco loco! &#128541; (Traigo el dato desde el Back-End)'>
                <Row justify="center">
                    <Col span={4}>
                        <Image
                            src={character.image}
                        />
                    </Col>
                </Row>
                <Form
                    form={form}
                    style={{ padding: 30 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <Form.Item
                        labelCol={{ span: 1 }}
                        label="Nombre"
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 1 }}
                        label="Tipo"
                        name="type"
                        rules={[{ required: true, message: 'Please input your type!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 1 }}
                        label="Especie"
                        name="species"
                        rules={[{ required: true, message: 'Please input your species!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 1 }}
                        label="Género"
                        name="gender"
                        rules={[{ required: true, message: 'Please input your gender!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="favorite">
                        <Checkbox checked={favoriteChk} onChange={handleFavorite}>Favorito?</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" onClick={() => { navigate('/') }}>
                            <ArrowLeftOutlined /> ATRAS
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Divider orientation="left">Episodios en los que aparece {character.name}</Divider>
            <List
                pagination={{
                    pageSize: 3,
                }}
                bordered
                dataSource={character?.episode}
                renderItem={item => (
                    <List.Item>
                        <Typography.Text mark>{item.air_date}</Typography.Text> {item.name}
                    </List.Item>
                )}
            />
        </div>
    )
}

export default Character;