import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, List, Card, Typography, Pagination, Space, Tooltip, Button, Modal } from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { GET_CHARACTERS } from '../graphql/Queries';
import { getCookie } from '../utils/utils';

const { Title } = Typography;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const CharacterList = () => {

  const [count, setCount] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('currentPage')) || 1);
  const [characters, setCharacters] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [favoritesHistory, setFavoritesHistory] = useState([]);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_CHARACTERS, {
    variables: { page: currentPage }
  });

  useEffect(() => {
    if (data) {

      const results = data.characters.results;
      const count = data.characters.info.count;
      const cookie = getCookie('favorites');

      if (!results.length) {
        setCurrentPage(1);
      }

      if (cookie) {
        setFavorites([...JSON.parse(cookie)]);
      }

      // Datos generales
      setCharacters([...results]);
      setCount(count);
    }
    return () => { }
  }, [data])


  const handlePaginate = (page, pageSize) => {
    setCurrentPage(page);
    localStorage.setItem('currentPage', page);
  }

  const handleModal = () => {
    const cookie = getCookie('favorites');
    if (cookie) {
      const data = JSON.parse(cookie);
      setFavoritesHistory(data.filter(d => d.favorite == true));
    }
    setVisible(true);
  }

  return (
    <div style={{ padding: 20 }}>

      <Title level={2}>Lista de personajes (Paginado por Back-End pasando parámetros a GraphQL)</Title>
      <Button type="primary" icon={<StarFilled />} style={{ marginTop: 20 }} onClick={handleModal}>Favoritos</Button>

      <Card title="The Rick and Morty" style={{ width: '100%', marginTop: 10 }}>
        <List
          itemLayout="vertical"
          dataSource={characters}
          size="default"
          renderItem={item => (
            <List.Item
              key={item.id}
              actions={[
                <IconText icon={favorites.find(d => d.id == item.id)?.favorite ? StarFilled : StarOutlined} key="list-vertical-star-o" />,
                <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
              ]}>
              <List.Item.Meta
                avatar={
                  <a onClick={() => { navigate(`/character/${item.id}`) }}>
                    <Tooltip placement="top" title={`Saber más sobre ${item.name}`}>
                      <Avatar src={item.image} />
                    </Tooltip>
                  </a>
                }
                title={<a href="#">{item.title}</a>}
                description={`¡${item.name}! un personaje muy divertido y pertenece a la especie ${item.species}.`}
              />
            </List.Item>
          )}
        />
      </Card>

      <Pagination showQuickJumper style={{ marginTop: 20 }} current={currentPage} total={count} onChange={handlePaginate} />

      <Modal
        title="Mis favoritos"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <List
          pagination={{
            pageSize: 7,
          }}
          bordered
          dataSource={favoritesHistory}
          renderItem={item => (
            <List.Item>
              <Avatar src={item.image} /> <Typography.Text>{item.name}</Typography.Text>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
}

export default CharacterList;
