import React, { useEffect } from 'react';

const Ejercicio1 = () => {

    useEffect(() => {
        // Resultado
        sum([1, 2, 3, 4, 5, 6, 15, 17, 18, 20, 21, 30, 44, 55, 32, 5, 8, 19], (result => console.log(result)))
        return () => { }
    }, [])

    const sum = (vector, callback) => {
        const vectorNew = vector.map((num) => {
            if (num > 20) {
                return 20;
            } else {
                return num;
            }
        });
        callback(vectorNew);
    }

    return (
        <div>Ejercicio1</div>
    )
}

export default Ejercicio1;