import 'antd/dist/antd.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Character from './components/Character';
import CharacterList from './components/CharacterList';
import Ejercicio1 from './components/Ejercicio1';
import Ejercicio2 from './components/Ejercicio2';

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path='/' element={<CharacterList />} />
        <Route path='/character/:id' element={<Character />} />
        <Route path='/ejercicio1' element={<Ejercicio1 />} />
        <Route path='/ejercicio2' element={<Ejercicio2 />} />
      </Routes>
    </Router>
  );
}

export default App;
