import React, { useEffect } from 'react';
import ListNicknames from './ListNicknames'

const Ejercicio2 = () => {


    return (
        <ListNicknames
            names={["Triviño TI", "Homer Dev", " ", " Ragnar Front", "Loki UX"]}
            order="ASC"
        />
    )
}

export default Ejercicio2;