import { gql } from 'apollo-boost';

export const GET_CHARACTERS = gql `
  query Characters ($page: Int) {
    characters (page: $page) {
      results {
        id
        name
        image
        species
      }
      info {
        count
        pages
        next
        prev
      }
    }
  }
`;

export const GET_CHARACTER = gql `
  query Character ($id: ID!) {
    character (id: $id) {
      id
      name
      type
      image
      status
      gender
      species
      episode {
        name
        air_date
      }
    }
  }
`;