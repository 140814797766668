
/**
 * Guarda una Cookie
 * @param {*} key 
 * @param {*} value 
 * @param {*} days 
 * @returns 
 */
const writeCookie = (key, value, days) => {
    var date = new Date();

    // Default at 365 days.
    days = days || 365;

    // Get unix milliseconds at current time plus number of days
    date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000

    window.document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";

    return value;
};

/**
 * 
 * @param {*} cname 
 * @returns 
 */
const getCookie = (cname) => {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export {
    writeCookie,
    getCookie
}