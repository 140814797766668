import React, { useEffect, useState } from 'react';

const ListNicknames = ({ names, order }) => {

  const [data, setData] = useState([]);

  useEffect(() => {

    if (names) {
      if (order == 'ASC') {
        setData([...names.sort()]);
      } else {
        setData([...names.reverse()]);
      }
      return () => { }
    }
  }, [names])
  

  return (
    <ul>
      {data.map(d => <li key={d}>{d}</li>)}
    </ul>
  )
}

export default ListNicknames;